export const Sign = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.24" cx="12" cy="12" r="11.5" stroke="#D7FF36" />
    <rect width="24" height="24" rx="12" fill="#D7FF36" />
    <path
      d="M13.6297 16.1959C13.4577 16.2779 13.2567 16.3389 13.0292 16.3799L9.74417 16.9549C8.64917 17.1499 8.00411 16.6999 7.81411 15.6049L7.04422 11.2299C6.84922 10.1349 7.29919 9.48994 8.39419 9.29494L9.29312 9.13692C9.39562 9.11892 9.4852 9.20843 9.4667 9.31093L8.87869 12.6399C8.61369 14.1549 9.32865 15.1749 10.8387 15.4399C10.8387 15.4399 13.4482 15.8984 13.5827 15.9244C13.7487 15.9544 13.7752 16.1314 13.6297 16.1959ZM16.9562 9.55293L16.1841 13.9299C15.9981 14.9839 15.3902 15.4344 14.3677 15.2944C14.3287 15.2889 14.2937 15.2889 14.2532 15.2819L10.9706 14.7029C9.87611 14.5099 9.42568 13.8664 9.61868 12.7719L10.2902 8.96391L10.3907 8.39491C10.5837 7.30041 11.2272 6.84992 12.3217 7.04292L15.6046 7.62193C16.6986 7.81493 17.1492 8.45893 16.9562 9.55293ZM13.7766 12.9479C13.8126 12.7439 13.6767 12.5494 13.4727 12.5134L11.4207 12.1514C11.2177 12.1149 11.0222 12.2519 10.9867 12.4554C10.9507 12.6594 11.0867 12.8539 11.2907 12.8899L13.3427 13.2519C13.3647 13.2559 13.3866 13.2574 13.4081 13.2574C13.5866 13.2579 13.7446 13.1299 13.7766 12.9479ZM15.2981 11.5174C15.3341 11.3134 15.1982 11.1189 14.9942 11.0829L11.7117 10.5039C11.5097 10.4684 11.3131 10.6044 11.2776 10.8079C11.2416 11.0119 11.3777 11.2064 11.5817 11.2424L14.8642 11.8214C14.8862 11.8254 14.9082 11.8269 14.9297 11.8269C15.1082 11.8269 15.2661 11.6989 15.2981 11.5174ZM15.6482 9.88643C15.6842 9.68243 15.5481 9.48792 15.3441 9.45192L12.0617 8.87291C11.8597 8.83691 11.6632 8.97343 11.6277 9.17693C11.5917 9.38093 11.7277 9.57544 11.9317 9.61144L15.2141 10.1904C15.2361 10.1944 15.2582 10.1959 15.2797 10.1959C15.4582 10.1964 15.6162 10.0684 15.6482 9.88643Z"
      fill="white"
    />
  </svg>
)
